<template>
  <div>
    <router-view></router-view>
    <notifications position="bottom right"></notifications>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
	/* Import Font Awesome 5 Icons Set */
	// $fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
	// @import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
	// @import '~@fortawesome/fontawesome-free/scss/solid.scss';
	// @import '~@fortawesome/fontawesome-free/scss/regular.scss';
	// @import '~@fortawesome/fontawesome-free/scss/brands.scss';

  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
